<template>
  <div class="note-book">
    <div class="book-side">
      <div class="info-left-line" @click="changeTerminal('pc')">
        电脑端操作手册
      </div>
      <div v-show="isPc === 'pc'" class="out">
        <div class="link-box" v-for="(item, index) in catalogList" :key="index">
          <div
            :class="['catalog', index === logIndex ? 'active' : '']"
            @click="changeLog(index, item.name)"
          >
            <img :src="item.img ? item.img : ''" alt="" />
            <span> {{ item.name }}</span>
          </div>
          <div
            class="children-box"
            v-show="item.children.length && isShowChildren === item.name"
          >
            <div
              :class="[
                'children-items',
                childrenIndex === item1.name ? 'active' : '',
              ]"
              v-for="(item1, index1) in item.children"
              :key="index1"
              @click="changeChildren(item1.name)"
            >
              {{ item1.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="info-left-line" @click="changeTerminal('mobile')">
        手机端操作手册
      </div>
      <div v-show="isPc === 'mobile'" class="out">
        <div
          class="link-box"
          v-for="(item, index) in catalogMobileList"
          :key="index"
        >
          <div
            :class="['catalog', index === logIndex ? 'active' : '']"
            @click="changeLog(index, item.name)"
          >
            <img :src="item.img ? item.img : ''" alt="" />
            <span> {{ item.name }}</span>
          </div>
          <div
            class="children-box"
            v-show="item.children.length && isShowChildren === item.name"
          >
            <div
              :class="[
                'children-items',
                childrenIndex === item1.name ? 'active' : '',
              ]"
              v-for="(item1, index1) in item.children"
              :key="index1"
              @click="changeChildren(item1.name)"
            >
              {{ item1.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="book-main"></div>
    <MyNav></MyNav>
  </div>
</template>

<script>
export default {
  components: {
    MyNav: () => import("@/components/MyNav.vue"),
  },
  data() {
    return {
      isPc: "",
      isShowChildren: undefined,
      childrenIndex: undefined,
      logIndex: undefined,
      catalogList: [
        {
          img: require("../assets/image/notebook/home.png"),
          name: "数字农业首页",
          icon: "index",
          line: 1,
          main: 1,
          id: 1,
          children: [],
        },
        {
          img: require("../assets/image/notebook/sy.png"),
          name: "溯源云",
          icon: "traceability",
          main: 1,
          line: 1,
          id: 2,

          children: [
            {
              name: "溯源条码工单管理",
              icon: "lot",
              id: 20,
            },
            {
              name: "溯源信息录入",
              id: 21,
              icon: "barCode",
            },
            {
              name: "溯源信息管理",
              id: 22,
              icon: "infoInput",
            },
          ],
        },
        {
          img: require("../assets/image/notebook/sb.png"),

          name: "设备云",
          icon: "gather",
          main: 1,
          id: 3,
          line: 1,
          children: [
            {
              name: "视频设备",
            },
            {
              name: "土壤检测设备",
            },
            {
              name: "气象设备",
            },
            {
              name: "苗情检测",
            },
            {
              name: "虫情监测设备",
            },
            {
              name: "智能灌溉阀门控制",
            },
            {
              name: "农机检测",
            },
          ],
        },
        {
          img: require("../assets/image/notebook/zs.png"),

          name: "知识云",
          id: 4,
          icon: "knowledge",
          main: 1,
          children: [],
          line: 1,
        },
        {
          img: require("../assets/image/notebook/gj.png"),

          name: "管家云",
          icon: "task",
          id: 5,
          main: 1,
          line: 1,
          children: [
            {
              name: "农事任务",
            },
            {
              name: "土地管理",
            },
            {
              name: "产品管理",
            },
            {
              name: "农资管理",
            },
            {
              name: "人员管理",
            },
          ],
        },
        {
          img: require("../assets/image/notebook/vr.png"),

          name: "VR全景",
          id: 6,
          children: [],
          icon: "vr",
        },

        {
          img: require("../assets/image/notebook/xt.png"),

          id: 12,
          name: "系统配置",
          icon: "farmpage",
          children: [
            {
              name: "农场信息配置",
              icon: "farm",
              id: 120,
            },
            {
              name: "作物分类",
              id: 121,
              icon: "cropType",
            },
            {
              name: "农资分类",
              id: 122,
              icon: "assetType",
            },

            {
              name: "流程模板配置",
              id: 123,
              icon: "processTemplate",
            },
            {
              name: "流程配置",
              id: 124,
              icon: "process",
            },
            {
              name: "个人资料",
              id: 125,
              icon: "datum",
            },
            {
              name: "账号设置",
              id: 126,
              icon: "password",
            },
            {
              name: "系统日志",
              id: 127,
              icon: "log",
            },
          ],
        },
      ],
      catalogMobileList: [
        {
          img: require("../assets/image/notebook/home.png"),
          name: "数字农业首页",
          icon: "index",
          line: 1,
          main: 1,
          id: 1,
          children: [],
        },
        {
          img: require("../assets/image/notebook/sy.png"),
          name: "溯源页",
          icon: "traceability",
          main: 1,
          line: 1,
          id: 2,

          children: [
            {
              name: "流程管理",
              icon: "lot",
              id: 20,
            },
            {
              name: "溯源信息录入",
              id: 21,
              icon: "barCode",
            },
          ],
        },
        {
          img: require("../assets/image/notebook/sb.png"),

          name: "设备页",
          icon: "gather",
          main: 1,
          id: 3,
          line: 1,
          children: [
            {
              name: "视频设备",
            },
            {
              name: "土壤检测设备",
            },
            {
              name: "气象设备",
            },
            {
              name: "苗情检测",
            },
            {
              name: "虫情监测设备",
            },
            {
              name: "智能灌溉阀门控制",
            },
            {
              name: "农机检测",
            },
          ],
        },
        {
          img: require("../assets/image/notebook/zs.png"),

          name: "知识云",
          id: 4,
          icon: "knowledge",
          main: 1,
          children: [],
          line: 1,
        },
        {
          img: require("../assets/image/notebook/gj.png"),

          name: "农事任务页",
          icon: "task",
          id: 5,
          main: 1,
          line: 1,
          children: [
            {
              name: "新增农事任务",
            },
          ],
        },

        {
          img: require("../assets/image/notebook/xt.png"),

          id: 12,
          name: "个人中心",
          icon: "farmpage",
          children: [
            {
              name: "人员管理",
              icon: "farm",
              id: 120,
            },

            {
              name: "供应商管理",
              id: 124,
              icon: "process",
            },
          ],
        },
      ],
    };
  },
  created() {
    sessionStorage.setItem("navLeft", "-100vh");
  },
  methods: {
    changeTerminal(type) {
      this.isShowChildren = undefined;
      this.childrenIndex = undefined;
      this.logIndex = undefined;
      this.isPc = type;
    },

    changeLog(i, name) {
      this.logIndex = i;
      this.isShowChildren = name;
    },
    changeChildren(name) {
      this.childrenIndex = name;
    },
  },
};
</script>

<style lang="less" scoped>
.note-book {
  width: 100%;
  height: 100vh;
  background-color: #071a2c;
  padding-top: 62px;
  display: flex;
  .book-side {
    height: 100%;
    width: 336px;
    padding: 32px 34px 32px 47px;
    background: #142d4c;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #3e90e5;
    }
    .info-left-line {
      font-size: 22px;
      font-weight: 400;
      color: #fff;
      padding: 0 0 0 15px;
      margin: 0 0 20px 0;
      position: relative;
      cursor: pointer;
      &::before {
        content: "";
        background-color: #3e90e5;
        width: 4px;
        height: 18px;
        border-radius: 2px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
    }
    .out {
      width: 100%;
      padding: 0 0 0 20px;
      .link-box {
        display: flex;
        flex-direction: column;
        width: 100%;
        .catalog {
          width: 100%;
          height: 36px;
          background: #224d7f;
          margin: 0 0 15px 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 0 0 0 32px;
          color: #fff;
          &.active {
            background-color: #3e90e5;
          }
          img {
            width: 24px;
            height: 24px;
            margin-right: 15px;
          }
          span {
            font-size: 14px;
          }
        }
        .children-box {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 4px 0 10px 67px;
          .children-items {
            font-size: 14px;
            color: #fff;
            line-height: 36px;
            cursor: pointer;
            &.active {
              color: #3e90e5;
            }
          }
        }
      }
    }
  }

  .book-main {
    flex: 1;
    // height: 100%;
    background: #0e2138;
    padding: 50px 85px;
  }
}
</style>
